<script setup>
import {ref, onMounted, useSlots} from 'vue';
import axios from 'axios';
import { useNotificationStore } from '@/stores/NotificationStore.js'
const notificationStore = useNotificationStore()

const props = defineProps({
    rebatedPrice: {
        type: Boolean,
        required: false,
        default: false,
    },
});


const slots = useSlots();

const paymentPeriods = [
    {
        period: 'monthly',
        label: _mft('shared:date.month', {count: 1}),
        price: '€149 / ' + _mft('shared:date.month', {count: 1}),
        monthlyPrice: null,
        renewal: _mft('company:myflowPro.montlyRenewal')
    },
    {
        period: 'yearly',
        label: _mft('shared:date.year', {count: 1}),
        price: props.rebatedPrice == 1 ? '€990 / ' + _mft('shared:date.year', {count: 1}) : '€1 490 / ' + _mft('shared:date.year', {count: 1}),
        monthlyPrice: props.rebatedPrice ? '82' : '124',
        renewal: _mft('company:myflowPro.yearlyRenewal')
    },
]

const subscriptionType = ref(null);
const nextPayment = ref(null);
const hasFuturePayment = ref(false);

const currentStatus = async () => {
    try {
        const response = await axios.get(route('companyPayment.current'));
        subscriptionType.value = response.data.subscription_type;
        nextPayment.value = response.data.next_expert_pro_billing_date;
        hasFuturePayment.value = response.data.has_future_payment;
    } catch (error) {
        console.error('Error fetching current status:', error);
    }
};

onMounted(() => {
    currentStatus();
});

</script>

<template>
    <div class="w-full">

        <div class="w-full max-w-3xl mx-auto mt-20 text-center">
            <div
                v-if="slots.intro"
                class="mb-12 lg:mt-12 lg:mb-36"
            >
                <slot name="intro"></slot>
            </div>

            <h1 class="mb-4">myflow Pro</h1>
            <!-- <p>
                {{ _mft('company:myflowPro.byline') }}
            </p>
            <p>
                {{ _mft('company:myflowPro.byline.cta') }}&nbsp;
                <a :href="route('pricing.expertPricing')" target="_blank" class="mf-link">
                    {{ _mft('shared:cta.clickHere') }}
                </a>.
            </p> -->

        </div>


        <div
            v-if="hasFuturePayment"
            class="max-w-3xl mx-auto mb-8"
        >
            <p class="text-lg italic font-bold text-center">
                {{ _mft('company:myflowPro.enabledUntil') }} {{ nextPayment }}
            </p>
        </div>

        <div class="grid max-w-3xl grid-cols-2 gap-4 mx-auto mt-8 lg:gap-8">
            <card-basic v-for="period in paymentPeriods" v-bind:key="period.period">
                <div class="flex flex-col justify-between">
                    <div class="text-center">
                        <h5 class="capitalize">
                            {{ period.label }}
                        </h5>
                        <hr class="mt-3 mb-3">
                        <div class="mb-3">
                            <b v-if="period.monthlyPrice && props.rebatedPrice">
                                <span class="line-through mr-1">€1490 / {{  _mft('shared:date.year', {count: 1}) }}</span>
                                (€500 {{ _mft('company:myflowPro.rebate') }}!)
                            </b><br>
                            <b>
                                {{ period.price }}
                            </b><br>
                            <div class="italic text-gray-600" v-if="period.monthlyPrice && !props.rebatedPrice">
                                (€124 / {{  _mft('shared:date.month', {count: 1}) }})
                            </div>
                            <div class="italic text-gray-600" v-if="period.monthlyPrice && props.rebatedPrice">
                                (€84 / {{  _mft('shared:date.month', {count: 1}) }})
                            </div>
                        </div>

                        <div
                            v-if="subscriptionType === period.period"
                            class="px-4 py-2 mt-4 mb-4 -mx-4 font-extrabold text-center md:-mx-6 lg:-mx-8 md:px-6 lg:px-8 text-md border-y bg-gray-50 text-myflowGreen"
                        >
                            {{ _mft('company:myflowPro.currentPlan') }}
                        </div>

                    </div>

                    <div class="flex flex-col items-center">
                        <div
                            v-if="subscriptionType === period.period"
                            class="mb-4 text-xs text-center text-gray-400"
                        >
                            {{ period.renewal }}
                        </div>

                        <a
                            v-if="subscriptionType === period.period"
                            :href="route('settings.company.stripe')"
                            class="w-full text-inherit"
                        >
                            <button-base class="justify-center w-full mt-5 !border-myflowRed !text-myflowRed">
                                {{ _mft('shared:action.cancel') }}
                            </button-base>
                        </a>

                        <a
                            v-if="subscriptionType !== 'no_subscription' && subscriptionType !== period.period"
                            :href="route('settings.company.stripe')"
                            class="w-full"
                        >
                            <button-success class="justify-center w-full mt-5" shimmer>
                                {{ subscriptionType === 'yearly' ? _mft('company:myflowPro.switchToMonthly') : _mft('company:myflowPro.switchToYearly') }}
                            </button-success>
                        </a>

                        <a
                            v-if="subscriptionType === 'no_subscription'"
                            :href="route('companyPayment.purchase', period.period)"
                            class="w-full"
                        >
                            <button-success class="justify-center w-full text-center" shimmer>
                                {{ _mft('shared:action.purchase') }}
                            </button-success>
                        </a>

                    </div>
                </div>
            </card-basic>
        </div>

    <myflow-pro-pricing></myflow-pro-pricing>

    </div>
</template>

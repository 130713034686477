<template>
    <div class="fixed bottom-0 left-0 right-0 z-40 bg-white md:left-52">
        <div class="grid items-center w-full grid-cols-3 p-4 mx-auto text-center max-w-7xl md:space-x-4">
            <div class="flex justify-end md:justify-start">
                <a
                    v-if="previousChapter.id"
                    :href="route('courses.showChapter', [courseId, previousChapter.id])"
                >
                    <span class="flex items-center">
                        <ArrowLeftIcon class="w-4 h-4 mr-1" />
                        <span class="hidden md:inline">{{ previousChapter.title }}</span>
                    </span>
                </a>
            </div>

            <div>
                <div
                    class="fixed inset-0"
                    v-if="open"
                    v-on:click="open = false"
                ></div>
                <div class="relative">
                    <ul
                        class="course-chapter-navigation absolute left-1/2 -translate-x-1/2 bg-white rounded-lg shadow-lg p-6 lg:p-8 mb-2 transition-all overflow-y-scroll w-full max-w-xs min-w-[260px] text-left"
                        :class="open ? 'bottom-full translate-y-0 duration-250 opacity-100 space-y-4' : 'opacity-0 top-full translate-y-10 duration-[0ms] space-y-2'"
                    >
                        <li
                            v-if="headline"
                            class="pb-2 text-lg border-b border-gray-200 md:-mt-2 lg:-mt-4"
                        >
                            <a :href="route('courses.show', [courseId])">
                                {{ headline }}
                            </a>
                        </li>
                        <li
                            class="block transition-all"
                            v-for="chapter in chapters"
                            :key="chapter.id"
                            :class="chapter.id === currentChapterId ? 'font-bold' : ''"
                        >
                            <a class="relative block" :href="route('courses.showChapter', [courseId, chapter.id])">
                                <i
                                    v-if="chapter.completed"
                                    class="absolute inline-block mr-1 text-xs -translate-y-1/2 fa-light fa-check right-full top-1/2 text-myflowGreen"
                                ></i>
                                {{ chapter.title }}
                            </a>
                        </li>
                    </ul>
                    <button
                        @click="toggleOpen"
                    ><span class="flex items-center">
                        <ViewListIcon class="w-4 h-4 mr-1" /> {{ _mft('course:chapter.domainNamePlural') }}
                    </span></button>
                </div>
            </div>
            <div class="flex justify-start md:justify-end">
                <a
                    v-if="nextChapter.id"
                    :href="route('courses.showChapter', [courseId, nextChapter.id])"
                >
                    <span class="flex items-center justify-end">
                        <span class="hidden md:inline">{{ nextChapter.title }}</span>
                        <ArrowRightIcon class="w-4 h-4 ml-1" />
                    </span>
                </a>
            </div>
        </div>
    </div>
</template>
<script>
import {
    ViewListIcon,
    ArrowLeftIcon,
    ArrowRightIcon
} from '@heroicons/vue/outline';

export default {
    components: {
        ViewListIcon,
        ArrowLeftIcon,
        ArrowRightIcon
    },
    props: {
        previousChapter: {
            type: Object,
            default: null
        },
        nextChapter: {
            type: Object,
            default: null
        },
        headline: {
            type: String,
            required: false
        },
        courseId: {
            type: Number,
            required: true
        },
        chapters: {
            type: Array,
            default: []
        },
        currentChapterId: {
            type: Number,
            default: null
        }
    },
    data() {
        return {
            open: false
        }
    },
    methods: {
        toggleOpen() {
            this.open = !this.open;
        }
    }
}

</script>
<style>
    ul.course-chapter-navigation {
        max-height: 75vh;
    }
    .intercom-lightweight-app-launcher, .intercom-namespace, #intercom-container, .intercom-launcher, .intercom-messenger-frame {
        //bottom: 75px !important;
    }
</style>

<script setup>
    import {ref, watch, onMounted} from 'vue'
    import StyleSetting from "./styleSetting.vue";
    import ButtonWithConfirmation from "../buttons/buttonWithConfirmation.vue";

    const emit = defineEmits(['close', 'update:style'])

    const props = defineProps({
        styles: {
            type: Object,
            required: false,
            default: {
                mobile: [],
                desktop: []
            }
        },
        defaultStyles: {
            type: Object,
            required: false,
            default: {
                mobile: [],
                desktop: []
            }
        },
        colCount: {
            type: Number,
            required: false,
            default: 1
        }
    })

    const _styles = ref(JSON.parse(JSON.stringify(props.styles)))
    const undoStyles = ref(JSON.parse(JSON.stringify(props.styles)))

    const styleSettingLabels = {
        backgroundColor: _mft('pagebuilder:styleEditor.backgroundColor'),
        color: _mft('pagebuilder:styleEditor.color'),
        backgroundUrl: _mft('pagebuilder:styleEditor.backgroundUrl'),
        backgroundMediaBankEntryId: _mft('pagebuilder:styleEditor.backgroundUrl'),
        overlay: _mft('pagebuilder:styleEditor.overlay'),
        padding: _mft('pagebuilder:styleEditor.padding'),
        margin: _mft('pagebuilder:styleEditor.margin'),
        maxWidth: _mft('pagebuilder:styleEditor.maxWidth'),
        rowMinHeight: _mft('pagebuilder:styleEditor.rowMinHeight'),
        verticalAlignment: _mft('pagebuilder:styleEditor.verticalAlignment'),
        layout: _mft('pagebuilder:styleEditor.layout'),
    }

    const currentSection = ref('large')
    const tabSections = [
        {
            label: _mft('pagebuilder:styleEditor.tab.default'),
            name: 'large',
        },
        {
            label: _mft('pagebuilder:styleEditor.tab.mobile'),
            name: 'small',
        },
    ]

    const setSection = (section) => {
        currentSection.value = section.name;
    }

    const close = () => {
        emit('close');
    }

    const saveAndClose = () => {
        emit('save')
        emit('close')
    }

    const undoAndClose = () => {
        _styles.value = JSON.parse(JSON.stringify(undoStyles.value))
        emit('update:style', _styles.value)
        emit('close')
    }

    const getPresets = (attribute) => {
        if (attribute === 'maxWidth') {
            return [
                {
                    label: _mft('pagebuilder:styleEditor.setting.maxWidth.narrow'),
                    value: '768px',
                },
                {
                    label: _mft('pagebuilder:styleEditor.setting.maxWidth.medium'),
                    value: '1024px',
                },
                {
                    label: _mft('pagebuilder:styleEditor.setting.maxWidth.wide'),
                    value: '1280px',
                },
                {
                    label: _mft('pagebuilder:styleEditor.setting.maxWidth.fullWidth'),
                    value: '100%'
                },
            ]
        }
        if (attribute === 'rowMinHeight') {
            return [
                {
                    label: _mft('pagebuilder:styleEditor.setting.rowMinHeight.auto'),
                    value: 'auto',
                },
                {
                    label: _mft('pagebuilder:styleEditor.setting.rowMinHeight.almostScreen'),
                    value: '80vh',
                },
                {
                    label: _mft('pagebuilder:styleEditor.setting.rowMinHeight.fullFcreen'),
                    value: '100vh',
                },
            ]
        }
        if (attribute === 'padding') {
            return [
                {
                    label: _mft('pagebuilder:styleEditor.setting.margin.none'),
                    value: [0, 0, 0, 0],
                },
                {
                    label: _mft('pagebuilder:styleEditor.setting.margin.aLittle'),
                    value: [16, 8, 16, 8]
                },
                {
                    label: _mft('pagebuilder:styleEditor.setting.margin.more'),
                    value: [48, 16, 48, 16],
                },
                {
                    label: _mft('pagebuilder:styleEditor.setting.margin.topAndBottom'),
                    value: [48, 0, 48, 0],
                },
                {
                    label: _mft('pagebuilder:styleEditor.setting.margin.spaceous'),
                    value: [128, 0, 128, 0],
                },

            ]
        }
        if (attribute === 'margin') {
            return [
                {
                    label: _mft('pagebuilder:styleEditor.setting.margin.none'),
                    value: [0, 0, 0, 0],
                },
                {
                    label: _mft('pagebuilder:styleEditor.setting.margin.aLittle'),
                    value: [16, 8, 16, 8]
                },
                {
                    label: _mft('pagebuilder:styleEditor.setting.margin.more'),
                    value: [32, 16, 32, 16],
                },
                {
                    label: _mft('pagebuilder:styleEditor.setting.margin.topAndBottom'),
                    value: [32, 0, 32, 0],
                },
                {
                    label: _mft('pagebuilder:styleEditor.setting.margin.spaceous'),
                    value: [64, 0, 64, 0],
                },

            ]
        }

        return []
    }

    watch(() => _styles.value, (newValue) => {
        emit('update:style', newValue)
    }, {deep: true})

    onMounted(() => {
        for (const [attribute, value] of Object.entries(props.defaultStyles.mobile)) {
            if (!_styles.value.mobile[attribute]) {
                _styles.value.mobile[attribute] = value
            }
        }
        for (const [attribute, value] of Object.entries(props.defaultStyles.desktop)) {
            if (!_styles.value.desktop[attribute]) {
                _styles.value.desktop[attribute] = value
            }
        }
    })

</script>

<template>
    <slideout-with-slots
        @close="close"
    >
        <template #title>
            <h2>{{ _mft('pagebuilder:styleEditor.title') }}</h2>
        </template>
        <template #actions>
            <div class="mb-2 flex justify-end space-x-2">
                <button-with-confirmation
                    @confirmed="undoAndClose"
                    modalClass="absolute -top-2 -right-2 p-2 bg-white rounded shadow-md z-10 text-black"
                    :buttonLabel="_mft('shared:cancel')"
                    buttonClass="text-wlPrimaryContrast"
                    :confirmButtonLabel="_mft('shared:confirm.continue')"
                    confirmButtonClass="myflow-basic-button--success whitespace-nowrap"
                    :confirm-text="_mft('pagebuilder:styleEditor.confirm.undoChanges')"
                ></button-with-confirmation>

                <button-success
                    @click="saveAndClose"
                >{{ _mft('shared:action.save') }}</button-success>
            </div>
        </template>
        <template #body>
            <div class="pb-24">
                <button-bar
                    :options="tabSections"
                    @select="setSection"
                />

                <div v-if="currentSection === 'large'">
                    <p class="-mt-2 text-center text-xs">{{ _mft('pagebuilder:styleEditor.tooltip.desktopSettings') }}</p>
                    <ul class="-mx-4 divide-y lg:-mx-6">
                        <li
                            v-for="(style, i) in _styles.desktop"
                            :key="i"
                            class="px-4 py-2 lg:px-6"
                        >
                            <StyleSetting
                                :styletype="i"
                                :title="styleSettingLabels[i]"
                                :presets="getPresets(i)"
                                :stylevalue="_styles.desktop[i]"
                                :col-count="colCount"
                                @update:model-value="_styles.desktop[i] = $event"
                            />
                        </li>
                    </ul>
                </div>

                <div v-if="currentSection === 'small'">
                    <p class="-mt-2 text-center text-xs">{{ _mft('pagebuilder:styleEditor.tooltip.mobileSettings') }}</p>
                    <ul class="-mx-4 divide-y lg:-mx-6">
                        <li
                            v-for="(style, i) in _styles.mobile"
                            :key="i"
                            class="px-4 py-2 lg:px-6"
                        >
                            <StyleSetting
                                v-if="i !== 'layout'"
                                :styletype="i"
                                :title="styleSettingLabels[i]"
                                :presets="getPresets(i)"
                                :stylevalue="_styles.mobile[i]"
                                @update:model-value="_styles.mobile[i] = $event"
                            />
                        </li>
                    </ul>
                </div>
            </div>
        </template>
    </slideout-with-slots>
</template>
